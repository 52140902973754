import { formatDistanceToNow, formatISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";

const defaultTick = 60 * 1000;

interface Props {
  dateTime: Date | null;
  tick?: number;
}

const RelativeDistance: React.FC<Props> = ({
  dateTime,
  tick = defaultTick,
}) => {
  const [text, setText] = useState(
    dateTime ? formatDistanceToNow(dateTime) : ""
  );

  const dateTimeIso = useMemo(
    () => (dateTime ? formatISO(dateTime) : undefined),
    [dateTime]
  );

  useEffect(() => {
    setText(dateTime ? formatDistanceToNow(dateTime) : "");

    const interval = setInterval(() => {
      setText(dateTime ? formatDistanceToNow(dateTime) : "");
    }, tick);

    return () => clearInterval(interval);
  }, [dateTime, tick]);

  return (
    <time dateTime={dateTimeIso} title={dateTimeIso}>
      {text}
    </time>
  );
};

export default RelativeDistance;
