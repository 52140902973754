import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export type PillType =
  | "danger"
  | "disabled"
  | "enabled"
  | "info"
  | "primary"
  | "warning";

export interface PillProps {
  children: React.ReactNode;
  className?: string;
  type?: PillType;
}

const Pill: React.FC<PillProps> = ({ children, className, type }) => (
  <span
    className={classNames(styles.pill, className, {
      [styles.danger]: type === "danger",
      [styles.disabled]: type === "disabled",
      [styles.enabled]: type === "enabled",
      [styles.info]: type === "info",
      [styles.primary]: type === "primary",
      [styles.warning]: type === "warning",
    })}
  >
    {children}
  </span>
);

export default Pill;
