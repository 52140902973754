/* eslint-disable react/jsx-no-useless-fragment */
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useRef, useState } from "react";

import IconKebab from "assets/images/icons/IconKebab";
import IconMeatballMenu from "assets/images/icons/IconMeatballMenu";
import Button, { ButtonBaseProps } from "components/common/Button";
import { useAppSelector } from "stores";
import { selectIsBackupPreview } from "stores/features/backups";
import { useClickOutside } from "utils/hooks";

import Tooltip from "../Tooltip";
import styles from "./styles.module.scss";

interface Value {
  label: string;
  value: string;
  icon?: React.ReactNode;
}

type Props = ButtonBaseProps & {
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  itemClassName?: string;
  onChange?: (value: string) => void;
  options?: Value[];
  optionsClassName?: string;
  showDropdownIndicator?: boolean;
  topPosition?: number;
  isMenu?: boolean;
  isVertical?: boolean;
};

const SelectButton: React.FC<Props> = ({
  allowOnPreviewMode,
  children,
  className,
  containerClassName,
  disabled,
  itemClassName,
  onChange = () => null,
  options = [],
  optionsClassName,
  showDropdownIndicator,
  isMenu,
  topPosition = 52,
  type = "outlined",
  isVertical,
  ...props
}) => {
  const isBackupPreview = useAppSelector(selectIsBackupPreview);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  const isDisabled = disabled || (isBackupPreview && !allowOnPreviewMode);

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSelect = useCallback(
    (value: string) => {
      onChange(value);
      setIsOpen(false);
    },
    [onChange]
  );

  useClickOutside({
    onClickOutside: handleClickOutside,
    ref: containerRef,
  });

  return (
    <div
      className={classNames(styles.selectButtonContainer, containerClassName)}
      ref={containerRef}
    >
      <Tooltip
        disabled={!isMenu}
        label={isOpen ? t("common.less") : t("common.more")}
        placement="bottom"
      >
        <Button
          {...props}
          disabled={isDisabled}
          className={classNames(
            styles.selectButton,
            showDropdownIndicator && styles.showIndicator,
            isDisabled && styles.disabled,
            className
          )}
          onClick={() => setIsOpen(!isOpen)}
          type={type}
        >
          {children}

          {isMenu && <>{isVertical ? <IconKebab /> : <IconMeatballMenu />}</>}

          {showDropdownIndicator && (
            <svg
              className={classNames(
                styles.indicatorIcon,
                isOpen && styles.open
              )}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 10L12 15L17 10H7Z"
                fill="currentColor"
                fillOpacity="0.54"
              />
            </svg>
          )}
        </Button>
      </Tooltip>

      {isOpen && (
        <div
          className={classNames(styles.selectButtonOptions, optionsClassName)}
          style={{ top: topPosition }}
        >
          {options.map((item) => (
            <Button
              className={classNames(
                styles.selectButtonItem,
                !item.icon && styles.noIcon,
                itemClassName
              )}
              onClick={() => handleSelect(item.value)}
              key={item.value}
            >
              {item.icon && (
                <div className={styles.selectButtonIcon}>{item.icon}</div>
              )}

              {item.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectButton;
