import React, { SVGProps } from "react";

const IconLicense: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 10.5C15.3133 10.5 14.1533 10.8519 13.1666 11.5112C12.1799 12.1705 11.4108 13.1075 10.9567 14.2039C10.5026 15.3003 10.3838 16.5067 10.6153 17.6705C10.8468 18.8344 11.4182 19.9035 12.2574 20.7426C13.0965 21.5818 14.1656 22.1532 15.3295 22.3847C16.4933 22.6162 17.6997 22.4974 18.7961 22.0433C19.8925 21.5892 20.8295 20.8201 21.4888 19.8334C22.1481 18.8467 22.5 17.6867 22.5 16.5C22.498 14.9093 21.8652 13.3843 20.7404 12.2596C19.6157 11.1348 18.0907 10.502 16.5 10.5ZM20.9325 15.75H19.467C19.3696 14.6192 19.0596 13.5169 18.5535 12.501C19.1748 12.8211 19.7131 13.2812 20.126 13.8451C20.5389 14.4089 20.815 15.0611 20.9325 15.75ZM15.042 15.75C15.1799 14.3946 15.6839 13.1022 16.5 12.0113C17.3164 13.1021 17.8207 14.3945 17.9587 15.75H15.042ZM17.9595 17.25C17.8213 18.6056 17.3168 19.898 16.5 20.9888C15.6836 19.8979 15.1793 18.6055 15.0413 17.25H17.9595ZM14.4465 12.501C13.9403 13.5169 13.6304 14.6192 13.533 15.75H12.0675C12.185 15.0611 12.4611 14.4089 12.874 13.8451C13.2869 13.2812 13.8252 12.8211 14.4465 12.501ZM12.0675 17.25H13.5322C13.6296 18.3808 13.9396 19.4831 14.4457 20.499C13.8246 20.1789 13.2864 19.7187 12.8737 19.1548C12.4609 18.5909 12.1849 17.9388 12.0675 17.25ZM18.5535 20.499C19.0597 19.4831 19.3697 18.3808 19.467 17.25H20.9325C20.815 17.9389 20.5389 18.5911 20.1261 19.155C19.7132 19.7189 19.1748 20.179 18.5535 20.499ZM4.5 10.5H9V12H4.5V10.5ZM4.5 4.5H13.5V6H4.5V4.5ZM4.5 7.5H13.5V9H4.5V7.5ZM4.5 18H9V19.5H4.5V18Z"
      fill="currentColor"
    />
    <path
      d="M9 22.5H3C2.6023 22.4996 2.221 22.3414 1.93978 22.0602C1.65856 21.779 1.5004 21.3977 1.5 21V3C1.5004 2.6023 1.65856 2.221 1.93978 1.93978C2.221 1.65856 2.6023 1.5004 3 1.5H15C15.3977 1.5004 15.779 1.65856 16.0602 1.93978C16.3414 2.221 16.4996 2.6023 16.5 3V9H15V3H3V21H9V22.5Z"
      fill="currentColor"
    />
  </svg>
);

export default IconLicense;
