import React, { SVGProps } from "react";

const IconImport: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V10.6667V14V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
      fill="#1A100B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5621 9.28608L17.8048 13.5287C18.065 13.7891 18.065 14.2112 17.8048 14.4715L13.5621 18.7141C13.3017 18.9745 12.8796 18.9745 12.6193 18.7141C12.3589 18.4539 12.3589 18.0317 12.6193 17.7713L15.7238 14.6668H9.33331V13.3335H15.7238L12.6193 10.2289C12.3589 9.96855 12.3589 9.54643 12.6193 9.28608C12.8796 9.02573 13.3017 9.02573 13.5621 9.28608Z"
      fill="#1A100B"
    />
  </svg>
);

export default IconImport;
