import React, { SVGProps } from "react";

const IconTransfer: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.45986 6.76687L2.40736 5.625C2.40736 4.92881 2.68392 4.26113 3.17621 3.76884C3.66849 3.27656 4.33617 3 5.03236 3H9.85186C10.548 3.00015 11.2156 3.27681 11.7077 3.76912L12.7945 4.85588C13.2867 5.34819 13.9542 5.62485 14.6504 5.625H19.8767C20.2415 5.62496 20.6023 5.70095 20.936 5.84811C21.2698 5.99528 21.5692 6.21039 21.8152 6.47973C22.0612 6.74906 22.2484 7.06672 22.3648 7.41242C22.4812 7.75812 22.5242 8.12429 22.4912 8.48756L21.6552 17.6751C21.5959 18.3273 21.295 18.9338 20.8115 19.3755C20.328 19.8172 19.6969 20.0622 19.042 20.0625H5.46024C4.80535 20.0622 4.17421 19.8172 3.69072 19.3755C3.20723 18.9338 2.90632 18.3273 2.84705 17.6751L2.01099 8.48756C1.95506 7.87959 2.11388 7.27123 2.45986 6.76819V6.76687ZM4.62549 6.9375C4.44316 6.93749 4.26283 6.97547 4.096 7.04901C3.92917 7.12255 3.77948 7.23005 3.65649 7.36464C3.53351 7.49924 3.43991 7.65798 3.38167 7.83076C3.32343 8.00353 3.30183 8.18654 3.31824 8.36813L4.1543 17.5556C4.18377 17.8817 4.33407 18.185 4.57569 18.406C4.81731 18.627 5.1328 18.7497 5.46024 18.75H19.042C19.3694 18.7497 19.6849 18.627 19.9265 18.406C20.1682 18.185 20.3185 17.8817 20.3479 17.5556L21.184 8.36813C21.2004 8.18654 21.1788 8.00353 21.1206 7.83076C21.0623 7.65798 20.9687 7.49924 20.8457 7.36464C20.7227 7.23005 20.5731 7.12255 20.4062 7.04901C20.2394 6.97547 20.0591 6.93749 19.8767 6.9375H4.62549ZM10.7811 4.69706C10.6591 4.57502 10.5142 4.47824 10.3548 4.41225C10.1953 4.34626 10.0244 4.31236 9.85186 4.3125H5.03236C4.68854 4.31244 4.35843 4.44729 4.11299 4.68806C3.86755 4.92884 3.72639 5.25631 3.71986 5.60006L3.72774 5.7825C4.00861 5.68012 4.31049 5.625 4.62549 5.625H11.7077L10.7811 4.69706Z"
      fill="currentColor"
    />
    <path
      d="M8.99967 12.2125V12.7875C8.99967 12.9463 9.04835 13.0818 9.14569 13.1942C9.24304 13.3065 9.36959 13.3626 9.52535 13.3626H12.6884L11.3719 14.6836C11.2581 14.7914 11.2012 14.9262 11.2012 15.0879C11.2012 15.2497 11.2581 15.3845 11.3719 15.4923L11.7089 15.8338C11.8197 15.9446 11.9545 16 12.1133 16C12.269 16 12.4053 15.9446 12.5221 15.8338L15.447 12.9044C15.5579 12.7935 15.6133 12.6588 15.6133 12.5C15.6133 12.3442 15.5579 12.208 15.447 12.0911L12.5221 9.17073C12.4083 9.05691 12.272 9 12.1133 9C11.9575 9 11.8227 9.05691 11.7089 9.17073L11.3719 9.50321C11.2581 9.61703 11.2012 9.75332 11.2012 9.91207C11.2012 10.0708 11.2581 10.2071 11.3719 10.3209L12.6884 11.6374H9.52535C9.36959 11.6374 9.24304 11.6935 9.14569 11.8058C9.04835 11.9182 8.99967 12.0537 8.99967 12.2125Z"
      fill="currentColor"
    />
  </svg>
);

export default IconTransfer;
