import React, { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  containerFill?: string;
};

const IconKebab: React.FC<Props> = ({ ...props }) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5 18C23.3284 18 24 17.3284 24 16.5C24 15.6716 23.3284 15 22.5 15C21.6716 15 21 15.6716 21 16.5C21 17.3284 21.6716 18 22.5 18Z"
      fill="#1A100B"
    />
    <path
      d="M22.5 24C23.3284 24 24 23.3284 24 22.5C24 21.6716 23.3284 21 22.5 21C21.6716 21 21 21.6716 21 22.5C21 23.3284 21.6716 24 22.5 24Z"
      fill="#1A100B"
    />
    <path
      d="M22.5 30C23.3284 30 24 29.3284 24 28.5C24 27.6716 23.3284 27 22.5 27C21.6716 27 21 27.6716 21 28.5C21 29.3284 21.6716 30 22.5 30Z"
      fill="#1A100B"
    />
  </svg>
);

export default IconKebab;
